import { usePageStore } from '@voix/store/pageStore'

export default function () {
  const pageStore = usePageStore()
  const siteId = computed(() => pageStore.currentPage?.site_id || 1)
  const languageCode = computed(() => pageStore.currentPage?.language_code || 'en')

  return {
    siteId,

    getCommonLayoutData: async (isVoixTemplatizer = false, exceptions: Array<string> = []) => {
      const resorts: any = ref(null)
      const brands: any = ref(null)
      const currentResort: any = ref(null)
      const footerCompanyMenu: any = ref(null)
      const footerResourcesMenu: any = ref(null)
      const footerTermsMenu: any = ref(null)

      if (!isVoixTemplatizer) {
        const resortsRequest: any = !exceptions.includes('resorts')
          ? $voixNuxtApi(`/api/resorts`)
          : null
        const brandsRequest: any = !exceptions.includes('brands')
          ? $voixNuxtApi(`/api/brands`)
          : null
        const currentResortRequest: any = !exceptions.includes('currentResort')
          ? $voixNuxtApi(`/api/sites/${siteId.value}/resorts${pageStore.currentPage?.path}?language=${pageStore.currentPage?.language_code}`)
          : null
        const footerCompanyMenuRequest: any = !exceptions.includes('footerCompanyMenu')
          ? $voixNuxtApi(`/api/sites/1/menus/Our%20Company%20Menu?language=${pageStore.currentPage?.language_code}`)
          : null
        const footerResourcesMenuRequest: any = !exceptions.includes('footerResourcesMenu')
          ? $voixNuxtApi(`/api/sites/1/menus/Footer%20Resources%20Menu?language=${pageStore.currentPage?.language_code}`)
          : null
        const footerTermsMenuRequest: any = !exceptions.includes('footerTermsMenu')
          ? $voixNuxtApi(`/api/sites/1/menus/Footer%20Terms%20Menu?language=${pageStore.currentPage?.language_code}`)
          : null

        const requests = []

        if (!exceptions.includes('resorts'))
          requests.push(resortsRequest)
        if (!exceptions.includes('brands'))
          requests.push(brandsRequest)
        if (!exceptions.includes('currentResort'))
          requests.push(currentResortRequest)
        if (!exceptions.includes('footerCompanyMenu'))
          requests.push(footerCompanyMenuRequest)
        if (!exceptions.includes('footerResourcesMenu'))
          requests.push(footerResourcesMenuRequest)
        if (!exceptions.includes('footerTermsMenu'))
          requests.push(footerTermsMenuRequest)

        await Promise.all(requests).then((values) => {
          let index = 0

          if (!exceptions.includes('resorts')) {
            resorts.value = values[index].data
            index++
          }
          if (!exceptions.includes('brands')) {
            brands.value = values[index].data
            index++
          }
          if (!exceptions.includes('currentResort')) {
            currentResort.value = values[index].data
            index++
          }
          if (!exceptions.includes('footerCompanyMenu')) {
            footerCompanyMenu.value = values[index].data
            index++
          }
          if (!exceptions.includes('footerResourcesMenu')) {
            footerResourcesMenu.value = values[index].data
            index++
          }
          if (!exceptions.includes('footerTermsMenu')) {
            footerTermsMenu.value = values[index].data
            index++
          }
        })
      }

      return {
        resorts: resorts.value,
        brands: brands.value,
        currentResort: currentResort.value,
        footerCompanyMenu: footerCompanyMenu.value,
        footerResourcesMenu: footerResourcesMenu.value,
        footerTermsMenu: footerTermsMenu.value,
      }
    },

    playaLayoutMounted: (currentResort: any) => {
      try {
        const id = window.setInterval(() => {
          if (typeof window.dataLayer === 'object' && currentResort.value?.data?.name) {
            window.dataLayer.push({
              hotelName: currentResort.value.data.name,
            })
            window.clearInterval(id)
          }
        }, 500)
      }
      catch (e) {
        console.error(e)
      }
    },

    setupHeader: (domainScriptId: string | null = null) => {
      const configuration = {
        htmlAttrs: {
          lang: languageCode
        },
        link: [
          {
            rel: 'preconnect',
            href: 'https://fonts.gstatic.com',
          },
          {
            rel: 'preconnect',
            href: 'https://fonts.googleapis.com',
          },
          {
            rel: 'shortcut icon',
            type: 'image/png',
            href: '/media/favicon.png',
          },
        ],
        script: [
          {
            innerHTML: '(function(w, d, s, l, i) {w[l] = w[l] || [];w[l].push({\'gtm.start\': new Date().getTime(),event: \'gtm.js\'});var f = d.getElementsByTagName(s)[0],j = d.createElement(s),dl = l != \'dataLayer\' ? \'&l=\' + l : \'\';j.async = true;j.src =\'https://www.googletagmanager.com/gtm.js?id=\' + i + dl;f.parentNode.insertBefore(j, f);})(window, document, \'script\', \'dataLayer\', \'GTM-NZBGTK4\'); ',
          },
        ],
      }

      if (domainScriptId) {
        configuration.script.push(
          {
            body: true,
            src: `https://cdn.cookielaw.org/consent/${domainScriptId}/OtAutoBlock.js`,
          } as any,
        )
        configuration.script.push(
          {
            'src': 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
            'data-domain-script': domainScriptId,
            'body': true,
          } as any,
        )
        configuration.script.push(
          {
            innerHTML: 'function OptanonWrapper() { }',
            body: true,
          } as any,
        )
      }

      useHead(configuration)
    },
  }
}
